$(document).ready(function() {
    $(document).ready(function () {
        var mySwiper = new Swiper ('.swiper-container', {
            pagination: '.swiper-pagination',
            paginationClickable: true,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            spaceBetween: 30,
            effect: 'fade',
            loop: true,
            autoplay: 5000,
            autoplayDisableOnInteraction: false,
        })
    });
});